import { useState, useRef } from 'react';

const useDragAndDrop = (fields) => {
    const [draggedIndex, setDraggedIndex] = useState(null);
    const itemRef = useRef(null);

    const handleDragStart = (index, entry) => {
        setDraggedIndex(index);
        itemRef.current = entry
    };

    const handleDragOver = (e, entry) => {
        if (itemRef.current === entry)
            e.preventDefault();
    };

    const handleDrop = (e, targetIndex, entry) => {
        e.preventDefault();
        if (draggedIndex !== targetIndex) {
            fields.move(draggedIndex, targetIndex);
        }
        setDraggedIndex(null);
        itemRef.current = ""
    };

    return { handleDragStart, handleDragOver, handleDrop };
};

export default useDragAndDrop;