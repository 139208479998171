import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FieldArray, getFormValues, arrayPush, arrayPop, change } from "redux-form";
import EntryTitle from "./EntryTitle";
import ItemTableHeader from "./ItemTableHeader";
import ItemTableFooter from "./ItemTableFooter";
import ItemTableBody from "./ItemTableBody";
import { formatDateField } from "../../utils";
import RenderDSF from "./RenderDSF"
import EntrySummary from "./EntrySummary"
import HelpTip from "../../../layout/HelpTip";
import {calcSingle} from "../calculations_single";

const DirectVictimEntries = props => {
  const { entries, victim, caseValues, dispatch  } = props;
  const startMinimized = true;


  useEffect(() => {
    const newValues = calcSingle(caseValues, victim, entries)
    const equal = JSON.stringify(caseValues._meta) == JSON.stringify(newValues._meta)

    if (equal) {
      return
    }

    dispatch(
        change(
            "caseDataForm",
            `_meta`,
            newValues._meta
        )
    );

  }, [caseValues, victim, entries]);

  const minimizeEntry = entryId => {
    let fieldName = "_meta[" + entryId + "].minimized";
    dispatch(
      change(
        "caseDataForm",
        fieldName,
        caseValues && caseValues._meta && caseValues._meta[entryId] && caseValues._meta[entryId].minimized !== undefined
          ? !caseValues._meta[entryId].minimized
          : !startMinimized
      )
    );
  };

  const copyData = () => {
    if (caseValues["DFT0"] && caseValues["DFT0"].length > 0) {
      let firstLine = true;
      caseValues["DFT0"].forEach(line => {
        if (Object.keys(line).length > 0) {
          if (line.startDate || line.endDate) {
            let newLine = {
              startDate: line.startDate, endDate: line.endDate, daysPerYear: 365
            };
            let tpt0Length = caseValues["TPT0"].length
            if (
              firstLine &&
              tpt0Length > 0 &&
              (//!caseValues["TPT0"][tpt0Length - 1].startDate &&     disabled, as this condition matches a last line inserted but not modified after an existing line (as any new line takes the previous line endDate + 1 as startDate)
                !caseValues["TPT0"][tpt0Length - 1].endDate &&
                !caseValues["TPT0"][tpt0Length - 1].durationNum &&
                !caseValues["TPT0"][tpt0Length - 1].hours &&
                !caseValues["TPT0"][tpt0Length - 1].periodRate &&
                !caseValues["TPT0"][tpt0Length - 1].lineAmount &&
                !caseValues["TPT0"][tpt0Length - 1].amountDirectInput)
            ) {
              dispatch(arrayPop("caseDataForm", "TPT0"));
            }
            firstLine = false;
            dispatch(arrayPush("caseDataForm", "TPT0", newLine));
          }
        }
      });
    }
  };

  const addLine = (entry, itemIndex, itemType) => {
    let newLine = {};
    const entryData = caseValues[entry.id + itemIndex];
    const lastLine = entryData[entryData.length - 1];

    if (itemType === "PGPA") {
      newLine.incomeType = newLine.durationType = "months";
    }

    if (itemType === "DSF") {
      newLine.arr = { past: [{}], future: [{ frequencyType: 1 }], annCap: {} };
      if (lastLine.arr && lastLine.arr.annCap) {
        newLine.arr.annCap.startDate = lastLine.arr.annCap.startDate;
        newLine.arr.annCap.capitalisationTable = lastLine.arr.annCap.capitalisationTable;
        newLine.arr.annCap.capitalisationEndAge = lastLine.arr.annCap.capitalisationEndAge;
        newLine.arr.annCap.capitalisationPER = lastLine.arr.annCap.capitalisationPER;
        newLine.arr.annCap.annuity = lastLine.arr.annCap.annuity;
        newLine.arr.annCap.annuityPercentage = lastLine.arr.annCap.annuityPercentage;
        newLine.arr.annCap.annuityPeriodicity = lastLine.arr.annCap.annuityPeriodicity;
      }
      // if (victim.consolidationDate) {
      //   let consolidationDate = new Date(victim.consolidationDate.split("-")[0],parseInt(victim.consolidationDate.split("-")[1])-1, victim.consolidationDate.split("-")[2]);
      //   newLine.arr.past[0].startDate = formatDateField(consolidationDate.getTime() + 86400000);
      // }
      
    } else if (entryData.length > 0) {
      if (lastLine.durationType) newLine.durationType = lastLine.durationType;
      else if (!newLine.durationType) newLine.durationType = "days";
      if (lastLine.periodRate) newLine.periodRate = lastLine.periodRate;
      if (lastLine.lossOfOpportunity) newLine.lossOfOpportunity = lastLine.lossOfOpportunity;
      if (lastLine.capitalisationTable) {
        newLine.capitalisationTable = lastLine.capitalisationTable;
        if (lastLine.startDate) {
          newLine.startDate = lastLine.startDate;
          if (lastLine.capitalisationEndAge) {
            newLine.capitalisationEndAge = lastLine.capitalisationEndAge;
            newLine.capitalisationPER = lastLine.capitalisationPER;
          }
        }
        if (lastLine.capitalisationTable === "PERdirectInput" && lastLine.capitalisationPER)
          newLine.capitalisationPER = lastLine.capitalisationPER;
      }
      if (lastLine.annuityPercentage) newLine.annuityPercentage = lastLine.annuityPercentage
      else newLine.annuityPercentage = 0
      if (lastLine.annuityPeriodicity) newLine.annuityPeriodicity = lastLine.annuityPeriodicity;
      if (itemType !== "DSA" && entry.id !== "PROV" && (lastLine.endDate || lastLine.startDate)) {
        let prevDate = lastLine.endDate;
        if (!prevDate)
          prevDate = lastLine.startDate;
        let lastEndDate = new Date(prevDate.split("-")[0],parseInt(prevDate.split("-")[1])-1,prevDate.split("-")[2]);
        newLine.startDate = formatDateField(lastEndDate.getTime() + 86400000);
      }
      if (lastLine.daysPerYear) newLine.daysPerYear = lastLine.daysPerYear;
      if (lastLine.daysPerYear) newLine.weeksPerYear = lastLine.weeksPerYear;
      if (lastLine.daysPerYear) newLine.monthsPerYear = lastLine.monthsPerYear;

      if (lastLine.periodType) newLine.periodType = lastLine.periodType;
    }

    dispatch(arrayPush("caseDataForm", entry.id + itemIndex, newLine));
  };

  const addTPPLine = (entry, itemIndex) => {
    let newLine = {};
    const entryName = entry.id + itemIndex;

    dispatch(arrayPush("caseDataForm", `TPP.${entryName}`, newLine));
  };

  return (
    <>
    {entries && entries.map(entry => {
        const minimized =  Boolean(caseValues && caseValues._meta && caseValues._meta[entry.id] && caseValues._meta[entry.id].minimized !== undefined
            ? caseValues._meta[entry.id].minimized
            : startMinimized)

         return  <Fragment key={entry.id}>
            <div className={isNaN(entry.id) ? "card border-primary" : ""}>
              <EntryTitle
                entry={entry}
                addLine={() => addLine(entry, 0)}
                victim={victim}
                entries={entries}
                minimizeEntry={() => minimizeEntry(entry.id)}
                minimized={
                  minimized
                }
                entryValues={caseValues && caseValues[entry.id+"0"]}
              />
              {//!caseValues[entry.id] ||
                caseValues &&
                caseValues._meta &&
                caseValues._meta[entry.id] &&
                  minimized === false &&
                entry.items && (
                  <div className="card-body">
                    {entry.id === "TPT" && (
                      <div>
                        <button type="button" className="btn btn-link btn-sm" onClick={() => copyData()}>
                          Copier les dates saisies en déficit fonctionnel temporaire (DFT)
                        </button>
                        <HelpTip helpText="copyDFT" />
                      </div>
                    )}
                    {entry.items.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          {entry.items.length > 1 && (
                            <h6 className="d-flex card-title">{item.label}<HelpTip helpText={item.itemType === "TPT" || item.itemType === "PGPA" ? "arrPast" : "arrFuture"} /></h6>
                          )}
                          <table className={`table table-hover table-sm${entry.items.length > 1 && i === 0 && caseValues[entry.id + i] && caseValues[entry.id + i].length < 2 ? " mb-4" : ""}`}>
                            {item.itemType !== "DSF" && <ItemTableHeader item={item} />}
                            <FieldArray
                              component={item.itemType === "DSF" ? RenderDSF : ItemTableBody}
                              name={entry.id + i}
                              itemId={i}
                              entry={entry.id}
                              item={item}
                              victim={victim}
                            />
                            <ItemTableFooter
                              item={item}
                              entry={entry}
                              itemIndex={i}
                              victim={victim}
                              addLine={() => addLine(entry, i, item.itemType)}
                              addTPPLine={() => addTPPLine(entry, i)}
                              disableEntryTPP={entry.disableEntryTPP}
                            />
                          </table>
                        </Fragment>
                      );
                    })}
                    {entry.items.length > 1 && (
                      <EntrySummary entry={entry} victim={victim} caseValues={caseValues} />
                    )}
                  </div>
                )}
            </div>
          </Fragment>}
        )}</>
  )
}

const mapStateToProps = (state) => {
  let entries = [];
  let caseValues = {};

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    entries = state.cases.case.json_data.caseDataForm;
    caseValues = getFormValues("caseDataForm")(state) || {};
  }

  return { entries, caseValues };
};

DirectVictimEntries.propTypes = {
  dispatch: PropTypes.func,
  entries: PropTypes.array,
  victim: PropTypes.object,
  caseValues: PropTypes.object,
};

export default connect(mapStateToProps)(DirectVictimEntries);
