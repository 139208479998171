import React, {Fragment, useEffect, useState} from "react";
import {Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "../css/bootswatch-materia.min.css";
import "../index.css";

import * as Sentry from "@sentry/react";
import {Provider} from "react-redux";
import {loadUser} from "../actions/auth";
import store from "../store";
import Dashboard from "./cases/Dashboard";
import Case from "./cases/physical_injury/case_layout/Case";
import CreateCaseWizard from "./cases/physical_injury/creation_wizard/CreateCaseWizard";
import PrivateRoute from "./common/PrivateRoute";
import Alerts from "./layout/Alerts";
import Header from "./layout/Header";
import NotFound from "./layout/NotFound";
import Login from "./users/Login";
import PasswordReset from "./users/PasswordReset";
import PasswordResetForm from "./users/PasswordResetForm";
import UserAccount from "./users/UserAccount";
import Verify from "./users/Verify";
import {LoadingComponent} from "./common/LoadingComponent";

// initialize sentry
Sentry.init({
    enabled: process.env.NODE_ENV !== 'development', // disabled in development mode
    dsn: "https://116ef6368d7a828a03ff73f78bc02931@o4507122998837248.ingest.de.sentry.io/4507123004080208",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        /^https:\/\/docker-prod\.quantum-jurisolutions\.fr/,
        /^https:\/\/dev\.quantum-jurisolutions\.fr/,
        /^https:\/\/quantum\.juri-solutions\.fr/,
        /^https:\/\/allianz-om.\.quantum\.juri-solutions\.fr/,
    ],
    environment: window.location.host === 'quantum.juri-solutions.fr'
        ? 'production'
        : window.location.host,
    // Session Replay
    replaysSessionSampleRate: 0, // we don't need to record sessions besides when there are errors
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Alert options
const alertOptions = {
    timeout: 3000,
    position: "top center",
    offset: "50px",
    zIndex: 9999,
};

function App() {
    const [userLoadDispatched, setUserLoadDispatched] = useState(false)

    useEffect(() => {
        store.dispatch(loadUser());
        setUserLoadDispatched(true)
    }, []);

    return (
        <Provider store={store}>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
                <Router>
                    <Fragment>
                        <Alerts className="header-spacer"/>
                        <Header/>
                        <div className="container-fluid header-spacer" id="main-content">
                            {userLoadDispatched ? <>
                                <Switch>
                                    <Route path="/premiere_connexion/:token" component={Verify}/>
                                    <Route exact path="/identification" component={Login}/>
                                    <Route exact path="/reinitialisation" component={PasswordReset}/>
                                    <Route path="/reinitialisation/:token" component={PasswordResetForm}/>
                                    <PrivateRoute exact path="/" component={Dashboard}/>
                                    <PrivateRoute exact path="/nouveau" component={CreateCaseWizard}/>
                                    <PrivateRoute exact path="/compte" component={UserAccount}/>
                                    <PrivateRoute path="/dossier/:caseId" component={Case}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            </> : <LoadingComponent/>}
                        </div>
                    </Fragment>
                </Router>
            </AlertProvider>
        </Provider>
    );
}

export default App;
